.hidden {
  display: none !important;
}
.transparent {
  opacity: 0;
}
.pointer {
  cursor: pointer;
}
.white {
  color: white;
}
.center {
  text-align: center;
}
.row {
  display: flex;
  flex-direction: row;
}

.modal-presale .ReactModal__Overlay {
  background-color: #000000aa !important;
}

.modal-presale .ReactModal__Content {
  max-width: 100%;
  max-height: 90%;
}

.modal-presale .popup__inner {
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

/* .modal-presale .ReactModal__Content {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  padding: 2%;
} */

.presale-wrapper {
  margin: 0 -30px;
  font-family: Roboto, sans-serif;
}
.presale-col {
  padding: 0 30px;
}
.popup__presale {
  flex-direction: column;
  /* width: 424px; */
  width: 472px;
  min-height: 400px;
  margin: 10px 0;
  position: relative;
}
.popup__presale.presale-col {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.popup__presale:nth-of-type(2) {
  width: auto;
}

.balance.row {
  justify-content: space-between;
}

.currency {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* icon */
.cryptoicon {
  background: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 2px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.yetiicon,
.cryptoicon svg {
  height: 32px !important;
  width: 32px !important;
}

/* symbol */
.cryptoname {
  font: bold 18px Roboto, sans-serif;
  text-shadow: 1px 1px 0px #00000033;
}
.searchFilter .cryptoname {
  font: bold 17px Roboto, sans-serif;
}
.pointer .cryptoname {
  text-decoration: underline;
  color: white;
}
.dropdown-arrow {
  display: flex;
}

.featured-cid {
  height: 24px;
  width: 24px;
}
.crypto-info.row {
  align-items: center;
  width: 100%;
}

/* input */
.yeti-input__wrapper {
  width: 100%;
}
.yeti-input_line {
  width: 100%;
  display: flex;
  padding: 10px;
  /* background: white; */
  background: #4295cf40;
  border-radius: 4px;
  border: 2px solid #4295cf;
  margin: 10px 0;
  color: #4295cf;
  justify-content: space-between;
  align-items: center;
}
.currencySearch {
  width: 100%;
  position: absolute;
  background: #000000;
  top: 0;
  padding-right: 60px;
  /* opacity: 1; */
}
.searchFilter {
  width: 100%;
  max-height: 343px;
  height: 343px;
  /* height: auto; */
  overflow: auto;
  color: #4295cf;
  border: 1px solid #4295cf;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background: #4295cf16;
}
.yeti-input_line.yeti-search {
  border-bottom: none;
  margin-bottom: 0;
  border-radius: 4px 4px 0 0;
  text-align: center;
}
.searchFilter .currencies {
  padding: 4px 0;
}
.searchFilter .currency {
  padding: 2px 16px;
  cursor: pointer;
  /* flex-flow: row-reverse; */
}

.yeti-input_line .currency {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* flex: 1 0 30%; */
  /* width: 30%; */
  margin-left: 10px;
}
.yeti-input_line input.yeti-input {
  flex: 1 1 70%;
  width: 70%;
  height: 100%;
  border: none;
  outline: none;
  color: white;
  background: transparent;
  font-size: x-large;
  text-align: right;
  /* margin-right: 16px; */
}
.yeti-input_line input.yeti-input.center {
  text-align: center;
}

.input-error {
  color: #f64f64;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  margin: 10px 0;
}
.input-error img {
  margin-right: 4px;
}

.yeti-input_line.error {
  border: 2px solid #f64f64;
}
.yeti-input_line.error input.yeti-input {
  color: #f64f64;
}

.updatingLoader {
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  position: absolute;
}

.updating {
  opacity: 0.5;
}

.metamask-wrapper {
  width: 100%;
}
.metamask-controls {
  padding-bottom: 24px;
  width: 100%;
}
.metamask-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0;
}
.metamask-controls-button {
  margin-bottom: 30px;
}
.connect-metamask {
  padding: 2px 16px;
}
.connect-metamask-logo {
  height: 48px;
  width: 48px;
}
.orders-history-button {
  margin-top: 50px;
  margin-bottom: 30px;
}

.create-order {
  margin-top: 50px;
  margin-bottom: 0;
}
.create-order.create-order-inactive {
  border: 2px solid #1a4d70;
  color: #363636;
  cursor: no-drop;
}

.orders-history {
  overflow: hidden;
  overflow-y: auto;
  max-height: 480px;
  margin-top: 10px;
  padding-right: 8px;
}

.order-wrapper {
  font: bold 18px Roboto, sans-serif;
  margin-bottom: 20px;
  padding: 12px;
  border: 2px solid #4295cf;
  border-radius: 4px;
}

.payment-status {
  text-transform: uppercase;
  margin-bottom: 12px;
  padding-bottom: 5px;
  border-bottom: 1px solid #4295cf;
  text-align: center;
  color: #fff937;
}
.payment-status.payment-status-finished {
  color: #08ff5f;
}
.payment-status.payment-status-failed,
.payment-status.payment-status-refunded,
.payment-status.payment-status-expired,
.payment-status.payment-status-partially_paid {
  color: #ff6a00;
}

.payment-row {
  margin: 6px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.payment-address-text {
  margin: 0 6px;
  font-size: 14px;
}

.payment-amount-text {
  margin: 0 6px;
}

.payment-amount-currency {
  margin-right: 6px;
}

.payment-row .copy.button.button--small {
  padding: 4px 6px;
}

.payment-row .button.button--small {
  padding: 4px 6px;
  margin-right: 4px;
}

.payment-memo-text {
  margin: 0 6px;
}

.payment-row.payment-product {
  /* padding-top: 8px; */
  /* border-top: solid 1px #225d85; */
  /* margin-top: 8px; */
}

.payment-tx-text {
  margin-left: 6px;
  /* font-size: 14px; */
}

.tx-link {
  display: flex;
  align-items: center;
}
.tx-link img {
  margin-left: 6px;
  height: 16px;
}

.pay-now {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.metamask-buttons.web3-pay {
  background-color: #3f8ec555;
  padding: 10px 20px;
  width: 66%;
  transition: background-color 300ms ease;
}
.metamask-buttons.web3-pay:hover {
  background-color: #3f8ec5e3;
  color: black;
  text-shadow: 1px 1px #0000008c;
  /* border: 2px solid #93d3ff; */
  transition: background-color 300ms ease;
}
.payment-pay-now-text {
  margin: 0 6px;
  color: #eee934;
}

@media only screen and (max-width: 1200px) {
  .modal-presale .popup__inner {
    align-items: center;
  }
  .popup__presale.presale-col {
    width: 70%;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 920px) {
  .custom-scroll.wrapper {
    overflow-y: initial;
    padding-right: 0;
  }
  .popup__presale.presale-col {
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .presale-wrapper {
    margin: 0 -5vw;
  }
  .popup__presale.presale-col {
    width: 95vw;
    max-width: 95vw;
    padding: 0 5vw;
    margin-left: 0;
  }
  .yeti-input_line input.yeti-input {
    font-size: large;
  }
  .metamask-buttons {
    font: 700 14px Roboto, sans-serif;
  }
  .payment-row {
    font-size: 16px;
  }
}

.button.button--third.button-presale {
  background-color: #c15000;
  color: #ffffff;
  border-color: #ed7800;
}